<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangSet",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
</style>